import {
    DownloadFileDialoguComponent
} from './folders/folder-steps/folder-step-two/download-file-dialogu/download-file-dialogu.component';
// Angular
import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DragDropModule} from '@angular/cdk/drag-drop';
// Translate Module
import {TranslateModule} from '@ngx-translate/core';
// NGRX
// UI
import {PartialsModule} from '../../../partials/partials.module';
// Core
// Auth
import {ModuleGuard} from '../../../../core/auth';
// Core => Services
import {ClientsService} from '../../../../core/ek-e-commerce/ek-services/clients.service';


// Core => Utils
import {HttpUtilsService, InterceptService, LayoutUtilsService, TypesUtilsService} from '../../../../core/_base/crud';
// Shared
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../../partials/content/crud';
// Components
// Products
// Orders
// Material
import {
    MAT_DATE_LOCALE,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
} from '@angular/material';
import {NgbDatepickerModule, NgbDropdownModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPermissionsGuard, NgxPermissionsModule} from 'ngx-permissions';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'

import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {MatDividerModule} from '@angular/material/divider';
import {CdkTableModule} from '@angular/cdk/table';
import {MatTableExporterModule} from 'mat-table-exporter';
import {ClipboardModule} from 'ngx-clipboard';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ResizableModule} from 'angular-resizable-element';
import {MatChipsModule} from '@angular/material/chips';
// tslint:disable-next-line:class-name
import {AngularEditorModule} from '@kolkov/angular-editor';
import {CoreModule} from '../../../../core/core.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MatListModule} from "@angular/material/list";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {EkECommerceComponent} from "./ek-e-commerce.component";
import {FoldersListComponent} from './folders/folders-list/folders-list.component';
import {foldersReducer} from "../../../../core/ek-e-commerce/ek-reducers/folder.reducers";
import {PointOfSaleListComponent} from './point-of-sale/point-of-sale-list/point-of-sale-list.component';
import {PointOfSaleEditComponent} from './point-of-sale/point-of-sale-edit/point-of-sale-edit.component';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {PointOfSaleEffects} from "../../../../core/ek-e-commerce/ek-effects/point-of-sale.effects";
import {possReducer} from "../../../../core/ek-e-commerce/ek-reducers/point-of-sale.reducers";
import {FoldersEffects} from "../../../../core/ek-e-commerce/ek-effects/folders.effects";
import {ClientsListPopUpComponent} from './folders/clients-list-pop-up/clients-list-pop-up.component';
import {ClientEffects} from "../../../../core/ek-e-commerce/ek-effects/client.effects";
import {clientsReducer} from "../../../../core/ek-e-commerce/ek-reducers/client.reducers";
import {FolderStepsComponent} from './folders/folder-steps/folder-steps.component';
import {FolderStepOneComponent} from './folders/folder-steps/folder-step-one/folder-step-one.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {FolderStepTwoComponent} from './folders/folder-steps/folder-step-two/folder-step-two.component';
import {FolderStepThreeComponent} from './folders/folder-steps/folder-step-three/folder-step-three.component';
import {FolderStepFourComponent} from './folders/folder-steps/folder-step-four/folder-step-four.component';
import {FolderStepFiveComponent} from './folders/folder-steps/folder-step-five/folder-step-five.component';
import {EkStepperComponent} from './folders/ek-stepper/ek-stepper.component';
import {OrderItemsComponent} from './folders/order-items/order-items.component';
import {EkProductEffects} from "../../../../core/ek-e-commerce/ek-effects/ekProducts.effects";
import {EkProductsService} from "../../../../core/ek-e-commerce/ek-services/ek-product.service";
import {ekProductsReducer} from "../../../../core/ek-e-commerce/ek-reducers/ekproduct.reducer";
import {FolderStepSixComponent} from './folders/folder-steps/folder-step-six/folder-step-six.component';
import {FolderStepSevenComponent} from './folders/folder-steps/folder-step-seven/folder-step-seven.component';
import {FolderStepEightComponent} from './folders/folder-steps/folder-step-eight/folder-step-eight.component';
import {orderSplitsReducer} from '../../../../core/ek-e-commerce/ek-reducers/orderSplit.reducers';
import {OrderSplitEffects} from "../../../../core/ek-e-commerce/ek-effects/orderSplit.effects";
import {MatExpansionModule} from "@angular/material/expansion";
import {FolderProcessComponent} from './folders/folder-process/folder-process.component';
import {SecondStepComponent} from './folders/second-step/second-step.component';
import {FilesCardComponent} from './reusable/files-card/files-card.component';
import {FourthStepComponent} from './folders/fourth-step/fourth-step.component';
import {
    OrderSplitProductDetailsComponent
} from './folders/order-items/order-split-product-details/order-split-product-details.component';
import {EKFooterComponent} from './ek-home-page/ek-footer/ekfooter.component';
import {DisplayComponent} from './ek-home-page/display/display.component';
import {EkFooterFilesComponent} from './ek-home-page/ek-footer/ek-footer-files/ek-footer-files.component';
import {EkQuestionAnswerComponent} from "./ek-home-page/ek-footer/ek-question-answer/ek-question-answer.component";
import {
    EkQuestionAnswerEditComponent
} from './ek-home-page/ek-footer/ek-question-answer/ek-question-answer-edit/ek-question-answer-edit.component';
import {EkFooterInformationService} from '../../../../core/ek-e-commerce/ek-services/ek-footer-information.service';
import {EkFooterFileService} from '../../../../core/ek-e-commerce/ek-services/ek-footer-file.service';
import {
    NewAddressPopUpComponent
} from "./folders/folder-steps/folder-step-seven/new-address-pop-up/new-address-pop-up.component";
import {
    SecondStepCheckListComponent
} from './folders/second-step/second-step-check-list/second-step-check-list.component';
import {DocumentResolver} from "./folders/second-step/resolver/document.resolver";
import {
    DocumentDisplayComponent
} from './folders/second-step/second-step-check-list/document-display/document-display.component';
import {
    DocumentCheckListComponent
} from './folders/second-step/second-step-check-list/document-check-list/document-check-list.component';
import {ChecklistService} from "../../../../core/ek-e-commerce/ek-services/checklist.service";
import {
    SecondStepConfirmationPopupComponent
} from './folders/folder-steps/folder-step-two/second-step-confirmation-popup/second-step-confirmation-popup.component';
import {
    FilesSecondErrorPopupComponent
} from './folders/folder-steps/folder-step-two/files-second-error-popup/files-second-error-popup.component';
import {AddCommentComponent} from './folders/folder-steps/folder-step-eight/add-comment/add-comment.component';
import {OrderSplitChangeStateComponent} from './folders/order-split-change-state/order-split-change-state.component';
import {
    OrderSplitProductsComponent
} from './folders/folder-steps/folder-step-one/order-split-products/order-split-products.component';
import {EditCommentComponent} from './folders/folder-steps/folder-step-eight/edit-comment/edit-comment.component';
import {
    CancelingReasonPopUpComponent
} from './folders/folders-list/canceling-reason-pop-up/canceling-reason-pop-up.component';
import {EkCustomersListComponent} from './ek-customers/ek-customers-list/ek-customers-list.component';
import {
    EkCustomerAddressListComponent
} from './ek-customers/ek-customer-address-list/ek-customer-address-list.component';
import {
    EkCustomerAddressEditComponent
} from './ek-customers/ek-customer-address-edit/ek-customer-address-edit.component';
import {EkCustomerEditComponent} from './ek-customers/ek-customer-edit/ek-customer-edit.component';
import {EkCategoryListComponent} from './ek-categories/ek-category-list/ek-category-list.component';
import {EkCategoryEditComponent} from './ek-categories/ek-category-edit/ek-category-edit.component';
import {EkClientEmailComponent} from './ek-customers/ek-client-email/ek-client-email.component';
import {EkProductsListDialogComponent} from './ek-products/products-list-dialog/products-list-dialog.component';
import {
    EkProductsCharacteristicsDialogComponent
} from "./ek-products/products-characteristics-dialog/products-characteristics-dialog.component";
import {EKProducts2ListComponent} from "./ek-products/products2-list/products2-list.component";
import {EkProducts2AddComponent} from "./ek-products/products2-add/products2-add.component";
import {EkProduct2EditComponent} from "./ek-products/product2-edit/product2-edit.component";
import {
    EkConfigurationCharacteristicComponent
} from "./ek-products/config/characteristics/configuration-characteristic/configuration-characteristic.component";
import {
    EkListsCaracteristicsComponent
} from "./ek-products/config/characteristics/lists-caracteristics/lists-caracteristics.component";
import {
    EkSpecificationEdit2Component
} from "./ek-products/config/characteristics/specification-edit2/specification-edit2.component";
import {EkCharacteristicsComponent} from "./ek-products/config/characteristics/characteristics.component";
import {EkConfiguration2AddComponent} from "./ek-products/config/configuration2-add/configuration2-add.component";
import {EkConfiguration2TabComponent} from "./ek-products/config/configuration2-tab/configuration2-tab.component";
import {EkEditImgComponent} from "./ek-products/config/edit-img/edit-img.component";
import {EkSettingsTabConfigComponent} from "./ek-products/config/settings-tab-config/settings-tab-config.component";
import {EkBrandsEditComponent} from "./ek-products/brands-edit/brands-edit.component";
import {EkClientAddressService} from "../../../../core/ek-e-commerce/ek-services/ek-client-address.service";
import {ImageViewerModule} from "ngx-image-viewer";
import {AnalystListPopupComponent} from './folders/folders-list/analyst-list-popup/analyst-list-popup.component';
import {
    DocumentsMessagesPopupComponent
} from './folders/second-step/documents-messages-popup/documents-messages-popup.component';
import {OrderStatisticsComponent} from './order-statistics/order-statistics.component';
import {OrderStatisticCartComponent} from './order-statistics/order-statistic-cart/order-statistic-cart.component';
import {FolderStateChangeComponent} from './folders/folder-state-change/folder-state-change.component';
import {ChangeHistoryComponent} from './folders/change-history/change-history.component';
import {changeHistoryReducer} from "../../../../core/ek-e-commerce/ek-reducers/change-history.reducers";
import {ChangeHistoryEffects} from "../../../../core/ek-e-commerce/ek-effects/change-history.effects";
import {
    FolderStateHistoryDetailsComponent
} from './folders/change-history/folder-state-history-details/folder-state-history-details.component';
import {FolderStateChangeEffects} from "../../../../core/ek-e-commerce/ek-effects/FolderStateChange.effects";
import {FolderStateChangeReducer} from "../../../../core/ek-e-commerce/ek-reducers/FolderStateChange.reducers";
import {ScoringComponent} from './folders/scoring/scoring.component';
import {StatisticsScoringComponent} from './folders/scoring/statistics-scoring/statistics-scoring.component';
import {
    ScoringCartStatisticComponent
} from './folders/scoring/statistics-scoring/scoring-cart-statistic/scoring-cart-statistic.component';
import {ScoringTableComponent} from './folders/scoring/scoring-table/scoring-table.component';
import {CircularSliderComponent} from './folders/scoring/circular-slider/circular-slider.component';

import {MatDatepickerModule} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {FeedbackStepComponent} from './folders/folder-steps/feedback-step/feedback-step.component'
import {EkCategoriesService} from "../../../../core/ek-e-commerce/ek-services/ek-categories.service";
import {configurationsReducer} from "../../../../core/ek-e-commerce/ek-reducers/configurations.reducer";
import {ConfigurationsEffects} from "../../../../core/ek-e-commerce/ek-effects/configurations.effects";
import {categoriesReducer} from "../../../../core/ek-e-commerce/ek-reducers/category.reducers";
import {CategoryEffects} from "../../../../core/ek-e-commerce/ek-effects/category.effects";
import {BrandEffects} from "../../../../core/ek-e-commerce/ek-effects/brand.effects";
import {brandsReducer} from "../../../../core/ek-e-commerce/ek-reducers/brand.reducers";
import {configurationReducer} from '../../../../core/ek-e-commerce/ek-reducers/configuration.reducer';
import {ConfigurationEffects} from "../../../../core/ek-e-commerce/ek-effects/configuration.effects";
import {CharacteristicEffects} from "../../../../core/ek-e-commerce/ek-effects/characteristic.effects";
import {characteristicsReducer} from "../../../../core/ek-e-commerce/ek-reducers/characteristic.reducers";
import {
    configurationCharacteristicsReducer
} from "../../../../core/ek-e-commerce/ek-reducers/configuration-characteristic.reducers";
import {
    ConfigurationCharacteristicEffects
} from "../../../../core/ek-e-commerce/ek-effects/configuration-characteristic.effects";
import {imagesReducer} from "../../../../core/ek-e-commerce/ek-reducers/image.reducers";
import {ImageEffects} from "../../../../core/ek-e-commerce/ek-effects/image.effects";
import {NotificationService} from "../../../../core/ek-e-commerce/ek-services/notification.service";
import {FeedbackCrembaComponent} from './folders/folders-list/feedback-cremba/feedback-cremba.component';
import {HistorySmsComponent} from './history-sms/history-sms.component';
import {SmsHistoryDetailsComponent} from './sms-history-details/sms-history-details.component';
import {smsHistoryReducer} from "../../../../core/ek-e-commerce/ek-reducers/sms-history.reducers";
import {SmsHistoryEffects} from "../../../../core/ek-e-commerce/ek-effects/sms-history.effects";
import {SmsHistoryDetailsEffects} from "../../../../core/ek-e-commerce/ek-effects/sms-history-details.effects";
import {smsHistoryDetailsReducer} from "../../../../core/ek-e-commerce/ek-reducers/sms-history-details.reducers";
import {CarouselImagesComponent} from "./ek-home-page/carousel-images/carousel-images.component";
import {carouselsReducer} from "../../../../core/home-page/carousel/carousel.reducers";
import {CarouselEffects} from "../../../../core/home-page/carousel/carousel.effects";
import {VerifyOcrDialogComponent} from './folders/verify-ocr-dialog/verify-ocr-dialog.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { NotificationManagementByRoleComponent } from './notification-settings/notification-management-by-role/notification-management-by-role.component';
import {notificationRolesReducer} from "../../../../core/ek-e-commerce/ek-reducers/notificationManagement.reducer";
import {NotificationRoleEffects} from "../../../../core/ek-e-commerce/ek-effects/notificationRole.effects";
import { CommentsPopUpComponent } from './folders/comments-pop-up/comments-pop-up.component';

import { GlobalVariableComponent } from './global-variable/global-variable.component';

import {MatBadgeModule} from "@angular/material/badge";
import { ConfirmationDialogComponent } from './folders/confirmation-dialog/confirmation-dialog.component';
import { PhoneNumberValidationPopupComponent } from './folders/phone-number-validation-popup/phone-number-validation-popup.component';
import { RegionListComponent } from './region-management/region-list/region-list.component';
import { WilayaListPopUpComponent } from './region-management/wilaya-list-pop-up/wilaya-list-pop-up.component';
import { NewRegionComponent } from './region-management/new-region/new-region.component';
import { PosListPopUpComponent } from './region-management/pos-list-pop-up/pos-list-pop-up.component';
import { DiscountHistoryComponent } from './folders/folder-steps/folder-step-one/discount-history/discount-history.component';
import { ConfirmDuplicateDialogComponent } from './folders/folders-pop-up/components/confirm-duplicate-dialog/confirm-duplicate-dialog.component';



const routes: Routes = [
    {
        path: '',
        component: EkECommerceComponent,
        children: [
            {
                path: '',
                redirectTo: 'folders',
                pathMatch: 'full',
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'CREATE_FOLDER'],
                        redirectTo: '/error/403',
                    },
                    title: 'Dossiers'
                }
            },
            // ek Order
            {
                path: 'folders',
                component: FoldersListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'CREATE_FOLDER'],
                        redirectTo: '/error/403',
                    },
                    title: 'Dossiers'
                }
            },

            //add new folder
            {
                path: 'folder/add/:idClient',
                component: FolderStepsComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'CREATE_FOLDER'],
                        redirectTo: '/error/403',
                    },
                    title: 'Ajouter Dossier'
                }
            },


            //edit folder
            {
                path: 'folder/edit/:idOrder/:idFolder',
                component: FolderStepsComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'CREATE_FOLDER', 'READ_ORDERSPLIT'],
                        redirectTo: '/error/403',
                    },
                    title: 'Dossiers N°'
                }
            },
            {
                path: 'folder/edit/:idOrder/:idFolder/checklist/:idDoc',
                component: SecondStepCheckListComponent,
                resolve: {payload: DocumentResolver},
                // canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'READ_CHECKLIST'],
                        redirectTo: '/error/403',
                    },
                    title: 'Checklist'
                }
            },

            //pos management
            {
                path: 'point-of-sale',
                component: PointOfSaleListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'ALL_POINTRELAIT'],
                        redirectTo: '/error/403',
                    },
                    title: 'Point de vente'
                }
            },
            {
                path: 'point-of-sale/add',
                component: PointOfSaleEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER'],
                        redirectTo: '/error/403',
                    },
                    title: 'Ajouter un Point de vente'
                }
            },
            {
                path: 'point-of-sale/edit',
                component: PointOfSaleEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'READ_POINTRELAIT'],
                        redirectTo: '/error/403',
                    },
                    title: 'Modifier un Point de vente'
                }
            },
            {
                path: 'point-of-sale/edit/:id',
                component: PointOfSaleEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'ALL_POINTRELAIT'],
                        redirectTo: '/error/403',
                    },
                    title: 'Modifier un Point de vente'
                }
            },

            //home management
            {
                path: 'ek-home-page/ek-footer',
                component: EKFooterComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'ALL_HOME', 'READ_HOME','ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'EK Footer'
                }
            },
            {
                path: 'ek-home-page/ek-footer/ek-question-answer',
                component: EkQuestionAnswerComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'READ_QUESTION'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'ek-home-page/ek-footer/ek-question-answer/add',
                component: EkQuestionAnswerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'READ_QUESTION'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'ek-home-page/ek-footer/ek-question-answer/edit',
                component: EkQuestionAnswerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'READ_QUESTION'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'ek-home-page/ek-footer/ek-question-answer/edit/:id',
                component: EkQuestionAnswerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'READ_QUESTION'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'ek-home-page/ek-footer/:footerFileType',
                component: EkFooterFilesComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'ONLY_READ'],
                        redirectTo: '/error/403'
                    },
                    title: 'Footer -  '
                }
            },
            {
                path: 'ek-home-page/display',
                component: DisplayComponent,
            },
            {
                path: 'ek-home-page/carousel',
                component: CarouselImagesComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'ONLY_READ'],
                        redirectTo: '/error/403'
                    }
                }
            },
            //ek customer management
            {
                path: 'customers',
                component: EkCustomersListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'addOrder/customers',
                component: EkCustomersListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'customers/add',
                component: EkCustomerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'CREATE_CLIENT', 'UPDATE_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'addOrder/customers/add',
                component: EkCustomerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'CREATE_CLIENT', 'UPDATE_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'customers/edit',
                component: EkCustomerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT', 'CREATE_CLIENT', 'UPDATE_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'customers/edit/:id',
                component: EkCustomerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT', 'CREATE_CLIENT', 'UPDATE_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            //  Products management
            {
                path: 'products',
                component: EKProducts2ListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_PRODUCT', 'READ_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'products2/add',
                component: EkProducts2AddComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'products2/edit/:id',
                component: EkProducts2AddComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_PRODUCT', 'READ_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT'],
                        redirectTo: '/error/403'
                    }
                }
            },

            {
                path: 'configuration2/add',
                component: EkConfiguration2AddComponent,
                data: {
                    permissions: {
                        only: ['ALL_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT'],
                        redirectTo: '/error/403'
                    }
                }
            },

            //category management
            {
                path: 'categories',
                component: EkCategoryListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CATEGORY', 'READ_CATEGORY'],
                        redirectTo: '/error/403'
                    }
                }

            },

            //comments management
            {
                path: 'folder/edit/:idOrder/:idFolder/comments',
                component: FolderStepEightComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['UPDATE_COMMENTFOLDER', 'READ_COMMENTFOLDER'],
                        redirectTo: '/error/403',
                    },
                    title: 'Commentaires'
                }
            },

            //changeHistory management
            {
                path: 'changeHistory',
                component: ChangeHistoryComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'CREATE_FOLDER'],
                        redirectTo: '/error/403',
                    },
                    title: 'Historique des modifications'
                }
            },
            //history sms

            {
                path: 'smsHistory',
                component: HistorySmsComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HISTORYSMS'],
                        redirectTo: '/error/403',
                    },
                    title: 'Historique des SMS'
                }
            },
            // settings Notification
            {
                path: 'settingsNotification',
                component: NotificationSettingsComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME','ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'Configuration des notifications'
                }
            },
            // global variables
            {
                path: 'globalVariables',
                component: GlobalVariableComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME','ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'Taux de banque'
                }
            },
            // region management
            {
                path: 'regions',
                component: RegionListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_REGION'],
                        redirectTo: '/error/403',
                    },
                    title: 'Regions'
                }
            },
        ]
    }
];

// @ts-ignore
@NgModule({
    imports: [
        MatInputModule,
        MatSortModule,
        MatTableModule,
        AutocompleteLibModule,
        GalleryModule,
        LightboxModule,
        GallerizeModule,
        MatSlideToggleModule,
        DragDropModule,
        CoreModule,
        ResizableModule,
        CommonModule,
        AngularEditorModule,
        PartialsModule,
        HttpClientModule,
        NgxPermissionsModule.forChild(),
        RouterModule.forChild(routes),
        NgbProgressbarModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatChipsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatDividerModule,
        CdkTableModule,
        MatTableExporterModule,
        NgbDatepickerModule,
        ClipboardModule,
        ResizableModule,
        PerfectScrollbarModule,
        NgbDropdownModule,
        MatListModule,
        MatButtonToggleModule,
        MatDialogModule,
        FlexLayoutModule,
        MatFormFieldModule,
        //folder
        StoreModule.forFeature('folders', foldersReducer),
        EffectsModule.forFeature([FoldersEffects]),

        //point of sale
        StoreModule.forFeature('pos', possReducer),
        EffectsModule.forFeature([PointOfSaleEffects]),

        //clients
        StoreModule.forFeature('clients', clientsReducer),
        EffectsModule.forFeature([ClientEffects]),

        //notification Roles management
        StoreModule.forFeature('notification-management', notificationRolesReducer),
        EffectsModule.forFeature([NotificationRoleEffects]),

        //products
        StoreModule.forFeature('ek-products', ekProductsReducer),
        EffectsModule.forFeature([EkProductEffects]),
        MatExpansionModule,

        //orderSplit
        StoreModule.forFeature('order-splits', orderSplitsReducer),
        EffectsModule.forFeature([OrderSplitEffects]),


        //folder change history
        StoreModule.forFeature('change-history', changeHistoryReducer),
        EffectsModule.forFeature([ChangeHistoryEffects]),

        //sms history
        StoreModule.forFeature('history-sms', smsHistoryReducer),
        EffectsModule.forFeature([SmsHistoryEffects]),
        //sms history details
        StoreModule.forFeature('history-sms-details', smsHistoryDetailsReducer),
        EffectsModule.forFeature([SmsHistoryDetailsEffects]),

        //folder states changes
        StoreModule.forFeature('folder-state-change', FolderStateChangeReducer),
        EffectsModule.forFeature([FolderStateChangeEffects]),

        StoreModule.forFeature('configurations', configurationsReducer),
        EffectsModule.forFeature([ConfigurationsEffects]),

        StoreModule.forFeature('configuration', configurationReducer),
        EffectsModule.forFeature([ConfigurationEffects]),

        StoreModule.forFeature('categories', categoriesReducer),
        EffectsModule.forFeature([CategoryEffects]),

        StoreModule.forFeature('brands', brandsReducer),
        EffectsModule.forFeature([BrandEffects]),

        StoreModule.forFeature('characteristics', characteristicsReducer),
        EffectsModule.forFeature([CharacteristicEffects]),

        StoreModule.forFeature('configurationCharacteristics', configurationCharacteristicsReducer),
        EffectsModule.forFeature([ConfigurationCharacteristicEffects]),

        StoreModule.forFeature('images', imagesReducer),
        EffectsModule.forFeature([ImageEffects]),

        StoreModule.forFeature('carousels', carouselsReducer),
        EffectsModule.forFeature([CarouselEffects]),

        ImageViewerModule,
        MatDatepickerModule,
        SatDatepickerModule, SatNativeDateModule, MatBadgeModule, FlexModule, TranslateModule
    ],
    providers: [
        ModuleGuard,
        InterceptService,
        DatePipe,
        NotificationService,
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        TypesUtilsService,
        LayoutUtilsService,
        HttpUtilsService,
        ClientsService,
        EkProductsService,
        EkCategoriesService,
        TypesUtilsService,
        LayoutUtilsService,
        EkFooterFileService,
        EkFooterInformationService,
        ChecklistService,
        EkClientAddressService
    ],
    entryComponents: [
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
    ],

    declarations: [
        EkECommerceComponent,
        SecondStepConfirmationPopupComponent,
        FilesSecondErrorPopupComponent,
        FoldersListComponent,
        PointOfSaleListComponent,
        PointOfSaleEditComponent,
        ClientsListPopUpComponent,
        FolderStepsComponent,
        FolderStepOneComponent,
        FolderStepTwoComponent,
        FolderStepThreeComponent,
        FolderStepFourComponent,
        FolderStepFiveComponent,
        EkStepperComponent,
        OrderItemsComponent,
        FolderStepSixComponent,
        FolderStepSevenComponent,
        FolderStepEightComponent,
        FolderProcessComponent,
        SecondStepComponent,
        FilesCardComponent,
        FourthStepComponent,
        OrderSplitProductDetailsComponent,
        EKFooterComponent,
        EkFooterFilesComponent,
        EkQuestionAnswerComponent,
        EkQuestionAnswerEditComponent,
        NewAddressPopUpComponent,
        SecondStepCheckListComponent,
        DocumentDisplayComponent,
        DocumentCheckListComponent,
        AddCommentComponent,
        OrderSplitChangeStateComponent,
        EditCommentComponent,
        OrderSplitProductsComponent,
        CancelingReasonPopUpComponent,
        EkCustomersListComponent,
        EkCustomerAddressListComponent,
        EkCustomerAddressEditComponent,
        EkCustomerEditComponent,
        EkCategoryListComponent,
        EkCategoryEditComponent,
        EkClientEmailComponent,
        EkProductsListDialogComponent,
        EkProductsCharacteristicsDialogComponent,
        EKProducts2ListComponent,
        EkProducts2AddComponent,
        EkProduct2EditComponent,
        EkConfigurationCharacteristicComponent,
        EkListsCaracteristicsComponent,
        EkSpecificationEdit2Component,
        EkCharacteristicsComponent,
        EkConfiguration2AddComponent,
        EkConfiguration2TabComponent,
        EkEditImgComponent,
        EkSettingsTabConfigComponent,
        EkBrandsEditComponent,
        AnalystListPopupComponent,
        DocumentsMessagesPopupComponent,
        OrderStatisticsComponent,
        OrderStatisticCartComponent,
        FolderStateChangeComponent,
        ScoringComponent,
        StatisticsScoringComponent,
        ScoringCartStatisticComponent,
        ScoringTableComponent,
        CircularSliderComponent,
        DownloadFileDialoguComponent,
        ChangeHistoryComponent,
        FolderStateHistoryDetailsComponent,
        FeedbackStepComponent,
        FeedbackCrembaComponent,
        HistorySmsComponent,
        SmsHistoryDetailsComponent,
        DisplayComponent,
        CarouselImagesComponent,
        VerifyOcrDialogComponent,
        NotificationSettingsComponent,
        NotificationManagementByRoleComponent,
        CommentsPopUpComponent,
        GlobalVariableComponent,
        ConfirmationDialogComponent,
        PhoneNumberValidationPopupComponent,
        RegionListComponent,
        WilayaListPopUpComponent,
        NewRegionComponent,
        PosListPopUpComponent,
        DiscountHistoryComponent,
        ConfirmDuplicateDialogComponent
    ]
})
export class EkECommerceModule {
}
