import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {DocumentService} from '../../../../../../../core/services/document.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ocrInfo} from '../../../../../../../core/ek-e-commerce/ek-models/document-ocr-information';
import {
    forbiddenProvinceValidator,
    Wilaya,
    WilayaService
} from '../../../../../../../core/ek-e-commerce/ek-services/wilaya.service';
import {Town} from '../../../../../../../core/ek-e-commerce/ek-models/town';
import {map, startWith} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {ClientModel} from '../../../../../../../core/ek-e-commerce/ek-models/client.model';
import {DatePipe} from '@angular/common';
import {TownService} from '../../../../../../../core/ek-e-commerce/ek-services/town.service';
import {
    ClientOcrInformationService
} from '../../../../../../../core/ek-e-commerce/ek-services/clientOcrInformation.service';
import {SocialProfessional} from '../../../../../../../core/ek-e-commerce/ek-models/social-professional';
import {DecimalFormatPipe} from '../../../../../../../core/_base/layout';
import {FoldersService} from '../../../../../../../core/ek-e-commerce/ek-services/folders.service';
import {MatDatepicker} from '@angular/material/datepicker';
import {ActivityTranslatePipe} from '../../../../../../../core/_base/layout/pipes/domain-translate.pipe';
import {ProfessionTranslatePipe} from '../../../../../../../core/_base/layout/pipes/profession-translate.pipe';
import {BANK_LENDER} from '../../../../Shared/Constants/bank.lender';
import {FormsValidationService} from '../../../../Shared/services/forms-validation.service';
import Keyboard from "simple-keyboard";
import layout from "simple-keyboard-layouts/build/layouts/arabic";
import {FolderVerificationService} from "../../../../../../../core/services/folder-verification.service";

export interface List {
    name: string;
    value: string;
}

@Component({
    selector: 'kt-folder-step-three',
    templateUrl: './folder-step-three.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./folder-step-three.component.scss'],
    providers: [DecimalFormatPipe, ProfessionTranslatePipe, ActivityTranslatePipe]
})
export class FolderStepThreeComponent implements OnInit {

    @Input() ocrInfos!: ocrInfo;
    @Input() client: ClientModel;
    @Input() idFolder: number;
    @Output() clientInfoForm = new EventEmitter<FormGroup>();
    @Input() needSomeChanges: boolean;

    panelOpenState = false;
    panelOpenState0 = false;
    panelOpenState1 = false;
    panelOpenState2 = false;
    professionalClientCategory = '';
    socialClientCategory = '';
    selectedWilaya = '';
    form!: FormGroup;
    public wilayasList: Wilaya[] = [];
    public birthWilayasList: Wilaya[] = [];
    public employerWilayasList: Wilaya[] = [];
    socialProfessionalInfo: SocialProfessional;


    wilayaSubject$ = new BehaviorSubject<string>('');
    readonly loadingWilaya$ = this.wilayaSubject$.asObservable();

    townsSubject$ = new BehaviorSubject<Town[]>([]);
    readonly town$ = this.townsSubject$.asObservable();

    private PHONE_REGEX = /^(00213|\+213|0)(5|6|7)(\s*?[0-9]\s*?){5,8}$/;

    wilaya = '';
    currentRole = '';
    public towns: Town[] = [];
    filteredOptions: Observable<Wilaya[]>;
    filteredEmployerWilaya: Observable<Wilaya[]>;
    townFilteredOptions: Observable<Town[]>;
    activityDomainOptions: Observable<List[]>;
    activityDomainTwoOptions: Observable<List[]>;
    professionOptions: Observable<List[]>;
    bankLenderCreditOtherOptions: Observable<List[]>;
    carLoanLenderBankOptions: Observable<List[]>;
    mortgageLenderBankOptions: Observable<List[]>;

    folderState = '';

    persoSituList = [
        {name: 'Célibataire', value: 'SINGLE'},
        {name: 'Marié', value: 'MARRIED'},
        {name: 'Divorcé', value: 'DIVORCE'},
        {name: 'Veuf', value: 'WIDOWER'}
    ];

    // type EducationalLevel = 'LESS_THAN_HIGH_SCHOOL' | 'SECONDARY_LEVEL' | 'BACHELOR' | 'UNIVERSITY';
    instructionTypeList = [
        {name: 'Moins que le niveau secondaire', value: 'LESS_THAN_HIGH_SCHOOL'},
        {name: 'Niveau secondaire', value: 'SECONDARY_LEVEL'},
        {name: 'Bachelier', value: 'BACHELOR'},
        {name: 'Universitaire', value: 'UNIVERSITY'}
    ];

    genderList = [
        {name: 'Homme', value: 'Male'},
        {name: 'Femme', value: 'Female'},
    ];


    habiTypeList = [
        {name: 'Avec les parents', value: 'WITH_PARENTS'},
        {name: 'Propriétaire', value: 'OWNER'},
        {name: 'Logement de fonction', value: 'FUNCTIONAL_ACCOMMODATION'},
        {name: 'Location', value: 'LEASE'},
    ];

    profSituList = [
        {name: 'Retraité militaire', value: 'MILITARY_RETIRED'},
        {name: 'Employé', value: 'EMPLOYEE'},
        {name: 'Retraité', value: 'RETRIED'},
        {name: 'Militaire', value: 'MILITARY'}
    ];

    typeContratList = [
        {name: 'Contrat de travail à durée indéterminée (CDI)', value: 'CDI'},
        {name: 'Contrat à durée déterminée (CDD)', value: 'CDD'},
        {name: 'Non Concerné', value: 'OTHER'},
    ];
    @ViewChild('datePicker00') datePicker00!: MatDatepicker<Date>;
    @ViewChild('otherCreditEndDateDP') otherCreditEndDateDP!: MatDatepicker<Date>;
    @ViewChild('otherRentCreditEndDateDP') otherRentCreditEndDateDP!: MatDatepicker<Date>;
    @ViewChild('carCreditEndDateDP') carCreditEndDateDP!: MatDatepicker<Date>;
    @ViewChild('mortgageEndDateDP') mortgageEndDateDP!: MatDatepicker<Date>;
    @ViewChild('deliveryDateDP') deliveryDateDP!: MatDatepicker<Date>;
    @ViewChild('validationDateDP') validationDateDP!: MatDatepicker<Date>;
    @ViewChild('recruitmentDateDP') recruitmentDateDP!: MatDatepicker<Date>;
    @ViewChild('dateRetraiteDP') dateRetraiteDP!: MatDatepicker<Date>;

    filteredBirthWilayas: Observable<Wilaya[]>;
    keyboard: Keyboard;
    keyboards: Keyboard[] = [];
    private ocrSbj$ = new BehaviorSubject<ocrInfo>(null);
    public ocrInfosObs$ = this.ocrSbj$.asObservable();
    showKeyboards: boolean[] = [];

    prevValue2: string[] = [];

    constructor(public documentService: DocumentService,
                public folderVerificationService: FolderVerificationService,
                private fb: FormBuilder,
                private wilayaService: WilayaService, private townService: TownService,
                private datePipe: DatePipe, private activityTranslatePipe: ActivityTranslatePipe, private professionTranslatePipe: ProfessionTranslatePipe,
                private clientOcrInformationService: ClientOcrInformationService, private decimalFormatPipe: DecimalFormatPipe, private folderService: FoldersService,
                private formsValidationService: FormsValidationService,
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {
        // detect changes in client social information
        this.clientOcrInformationService.loadingSocialProfessionalSubject.subscribe(change => {
            if (change) {
                this.socialProfessionalInfo = change;
            }
        });

        this.folderService.folderStateSubj$.subscribe(state => this.folderState = state);

        this.wilayasList = this.wilayaService.getWilayasItems();
        this.birthWilayasList = this.wilayaService.getWilayasItems();
        this.employerWilayasList = this.wilayaService.getWilayasItems();

        this.documentService.ocrSubject.subscribe(res => {
            if (res) {
                this.getWilayaByTownName(res.personnelInformation?.commune);
                this.ocrInfos = res;
                this.ocrSbj$.next(res);
                this.createClientOcrFormGrp();
            }
        });

        this.loadingWilaya$.subscribe(wilaya => {
            if (wilaya) {
                this.form.get('wilaya').setValue(wilaya);
                this.selectedWilaya = wilaya;
            }
        });

        this.town$.subscribe(town => {
            if (town.length > 0) {
                this.towns = town;
            }
        });
        this.showKeyboards.push(false);

    }

    openDatePicker(): void {
        if (this.datePicker00) {
            this.datePicker00.open();
        }
    }

    openOtherCreditEndDateDP(): void {
        if (this.otherCreditEndDateDP) {
            this.otherCreditEndDateDP.open();
        }
    }

    openOtherRentCreditEndDateDP(): void {
        if (this.otherRentCreditEndDateDP) {
            this.otherRentCreditEndDateDP.open();
        }
    }

    openCarCreditEndDateDP(): void {
        if (this.carCreditEndDateDP) {
            this.carCreditEndDateDP.open();
        }
    }

    openMortgageEndDateDP(): void {
        if (this.mortgageEndDateDP) {
            this.mortgageEndDateDP.open();
        }
    }

    opendeliveryDatePicker(): void {
        if (this.deliveryDateDP) {
            this.deliveryDateDP.open();
        }
    }

    openValidationDatePicker(): void {
        if (this.validationDateDP) {
            this.validationDateDP.open();
        }
    }

    openRecruitmentDatePicker(): void {
        if (this.recruitmentDateDP) {
            this.recruitmentDateDP.open();
        }
    }

    openDateRetraitePicker(): void {
        if (this.dateRetraiteDP) {
            this.dateRetraiteDP.open();
        }
    }

    toggleKeyboard(index: number) {
        if (this.currentRole !== 'ROLE_COMMERCIAL_REGION_MANAGER') {
            this.showKeyboards[index] = !this.showKeyboards[index];
        }
    }

    createClientOcrFormGrp() {
        this.socialClientCategory = this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.socialClientCategory : '';
        this.professionalClientCategory = this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation?.professionalClientCategory : '';

        this.form = this.fb.group({
            socialClientCategory: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.socialClientCategory : '', Validators.compose([Validators.required])],
            educationalLevel: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation?.educationalLevel : '', Validators.compose([Validators.required])],
            dependentChild: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation?.dependentChild : ''],
            dependentOtherPeople: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation?.dependentOtherPeople : '', Validators.compose([Validators.required])],
            habitation: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation?.habitation : '', Validators.compose([Validators.required])],
            zipCode: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation?.zipCode : '', [this.formsValidationService.numericCharacters, Validators.minLength(5), Validators.maxLength(5)]],
            wilaya: new FormControl('', [forbiddenProvinceValidator(this.wilayasList)]),
            address: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation?.personnelSituationAddress : '', Validators.compose([Validators.required])],
            daira: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.daira : '', Validators.compose([Validators.required])],
            personnelSituationAddressAscii: [this.ocrInfos.personnelSituation?.personnelSituationAddressAscii ? this.ocrInfos.personnelSituation?.personnelSituationAddressAscii : '', Validators.compose([Validators.required])],
            commune: new FormControl(this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.commune : '', [Validators.required, this.forbiddenTownsValidator()]),
            communeCode: new FormControl(this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.communeCode : '', [Validators.required, Validators.maxLength(5)]),
            wilayaNameAsciiInArabic: [this.ocrInfos.informationAddress ? this.ocrInfos.informationAddress?.wilayaNameAsciiInArabic : '', Validators.compose([Validators.required, Validators.minLength(2)])],
            communeNameAsciiInArabic: [this.ocrInfos.informationAddress ? this.ocrInfos.informationAddress?.communeNameAsciiInArabic : '', Validators.compose([Validators.required, Validators.minLength(2)])],
            lastName: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.lastName : '', Validators.compose([Validators.required, Validators.minLength(2), this.formsValidationService.alphabeticCharacters])],
            firstName: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.firstName : '', Validators.compose([Validators.required, Validators.minLength(2), this.formsValidationService.alphabeticCharactersWithHyphens])],
            motherLastName: [this.ocrInfos.personnelInformation?.lastNameMother ? this.ocrInfos.personnelInformation?.lastNameMother : '', Validators.compose([Validators.required, Validators.minLength(2), this.formsValidationService.alphabeticCharacters])],
            motherFirstName: [this.ocrInfos.personnelInformation?.firstNameMother ? this.ocrInfos.personnelInformation?.firstNameMother : '', Validators.compose([Validators.required, Validators.minLength(2), this.formsValidationService.alphabeticCharacters])],
            lastNameMotherInArabic: [this.ocrInfos.personnelInformation?.lastNameMotherInArabic ? this.ocrInfos.personnelInformation?.lastNameMotherInArabic : '', Validators.compose([Validators.required, Validators.minLength(2)])],
            firstNameMotherInArabic: [this.ocrInfos.personnelInformation?.firstNameMotherInArabic ? this.ocrInfos.personnelInformation?.firstNameMotherInArabic : '', Validators.compose([Validators.required, Validators.minLength(2)])],
            fatherFirstName: [this.ocrInfos.personnelInformation?.firstNameFather ? this.ocrInfos.personnelInformation?.firstNameFather : '', Validators.compose([Validators.required, Validators.minLength(2), this.formsValidationService.alphabeticCharacters])],
            firstNameFatherInArabic: [this.ocrInfos.personnelInformation?.firstNameFatherInArabic ? this.ocrInfos.personnelInformation?.firstNameFatherInArabic : '', Validators.compose([Validators.required, Validators.minLength(2)])],
            gender: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.gender : '', Validators.compose([Validators.required])],
            maidenName: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.maidenName : ''],
            maidenNameInArabic: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.maidenNameInArabic : '', Validators.compose([Validators.minLength(2)])],
            lastNameEnArab: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.lastNameEnArab : '', Validators.compose([Validators.required])],
            fistNameEnArab: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.fistNameEnArab : '', Validators.compose([Validators.required])],
            placeOfBrith: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.placeOfBrith : '', Validators.compose([Validators.required, Validators.minLength(2), this.formsValidationService.alphabeticCharacters])],
            birthWilaya: new FormControl(this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.birthWilaya : '', forbiddenProvinceValidator(this.birthWilayasList)),
            placeOfBrithArabic: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.placeOfBrithArabic : '', Validators.compose([Validators.required])],
            codePlaceOfBirth: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.codePlaceOfBirth : '', Validators.compose([Validators.required])],
            cardType: [this.ocrInfos.personnelInformation?.idCardNumber ? 'Carte d\'identité biométrique' : 'Permis de conduire', Validators.compose([Validators.required])],
            idCardNumber: [this.ocrInfos.personnelInformation ? (this.ocrInfos.personnelInformation?.idCardNumber ? this.ocrInfos.personnelInformation?.idCardNumber : this.ocrInfos.personnelInformation?.driverLicenceNumber) : '', Validators.compose([Validators.required])],
            deliveryDaira: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.deliveryDaira : '', Validators.compose([Validators.required])],
            nationalIdNumber: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.nationalIdNumber : '', Validators.compose([Validators.required])],
            socialSecurityNumber: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.socialSecurityNumber : ''],
            actOfBrithNumber: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation?.actOfBrithNumber : ''],
            professionalClientCategory: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation?.professionalClientCategory : '', Validators.compose([Validators.required])],
            activityDomain: new FormControl(this.ocrInfos.professionalSituation?.activityDomain ? this.activityTranslatePipe.transform(this.ocrInfos.professionalSituation?.activityDomain) : '', Validators.compose([Validators.required])),
            activityDomainTwo: new FormControl(this.ocrInfos.professionalSituation?.activityDomaineBank ? this.activityTranslatePipe.transform(this.ocrInfos.professionalSituation?.activityDomaineBank) : '', Validators.compose([Validators.required])),
            profession: new FormControl(this.ocrInfos.professionalSituation ? this.professionTranslatePipe.transform(this.ocrInfos.professionalSituation?.profession) : '', Validators.compose([Validators.required])),
            professionalPhone: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation?.professionalPhone : '', Validators.compose([Validators.minLength(10), Validators.pattern(this.PHONE_REGEX)])],
            contractType: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation?.contractType : '', Validators.compose([Validators.required])],
            montant_net: [this.ocrInfos ? (this.ocrInfos.professionalSituation?.salary ? this.decimalFormat(this.ocrInfos.professionalSituation?.salary) : '') : ''],
            netSalaryRENA: [this.ocrInfos ? (this.ocrInfos.professionalSituation?.netSalaryRENA ? this.decimalFormat(this.ocrInfos.professionalSituation?.netSalaryRENA) : '') : ''],
            employer: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation?.employer : ''],
            employerWilaya: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation?.employerWilaya : '', [forbiddenProvinceValidator(this.employerWilayasList)]],
            employerAddress: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation?.employerAddress : ''],
            accountType: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation?.accountType : '', Validators.compose([Validators.required])],
            accountNumberCCP: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation?.accountNumberCCP : ''],
            accountNumberBank: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation?.accountNumberBank : ''],
            accountKey: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation?.accountKey : '', Validators.compose([Validators.required])],
            accountNumber: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation?.accountNumber : ''],
            birthDay: [this.ocrInfos.personnelInformation ? (this.ocrInfos.personnelInformation?.birthDay ? this.formatDate_(this.ocrInfos.personnelInformation?.birthDay) : '') : '', Validators.compose([Validators.required])],
            validationDate: [this.ocrInfos.personnelInformation ? (this.ocrInfos.personnelInformation?.validationDate ? this.formatDate_(this.ocrInfos.personnelInformation?.validationDate) : '') : '', Validators.compose([Validators.required])],
            recruitmentDate: [this.ocrInfos.professionalSituation ? (this.ocrInfos.professionalSituation?.recruitmentDate ? this.formatDate_(this.ocrInfos.professionalSituation?.recruitmentDate) : '') : ''],
            dateRetraite: [this.ocrInfos.professionalSituation
                ? (this.ocrInfos.professionalSituation?.professionalClientCategory === 'MILITARY_RETIRED'
                    ? (this.ocrInfos.professionalSituation?.radiationRecruitmentDate ? this.formatDate_(this.ocrInfos.professionalSituation?.radiationRecruitmentDate) : '')
                    : (this.ocrInfos.professionalSituation?.retraitDate ? this.formatDate_(this.ocrInfos.professionalSituation?.retraitDate) : ''))
                : ''],
            deliveryDate: [this.ocrInfos.personnelInformation ? (this.ocrInfos.personnelInformation?.deliveryDate ? this.formatDate_(this.ocrInfos.personnelInformation?.deliveryDate) : '') : '', Validators.compose([Validators.required])],
            mortgageAmount: [this.ocrInfos.financialSituation?.mortgageAmount ? this.decimalFormat(this.ocrInfos.financialSituation?.mortgageAmount) : ''],
            mortgageEndDate: [this.ocrInfos.financialSituation?.mortgageEndDate ? (this.ocrInfos.financialSituation?.mortgageEndDate ? this.formatDate_(this.ocrInfos.financialSituation?.mortgageEndDate) : '') : ''],
            mortgageLenderBank: [this.translateLenderBank(this.ocrInfos.financialSituation?.mortgageLenderBank)],
            carLoanAmount: [this.decimalFormat(this.ocrInfos.financialSituation?.carLoanAmount)],
            carCreditEndDate: [this.ocrInfos.financialSituation?.carCreditEndDate ? this.formatDate_(this.ocrInfos.financialSituation?.carCreditEndDate) : ''],
            carLoanLenderBank: [this.translateLenderBank(this.ocrInfos.financialSituation?.carLoanLenderBank)],
            // typeOfCreditOther: [this.ocrInfos.financialSituation?.typeOfCreditOther],
            otherCredit: [this.ocrInfos.financialSituation?.otherCredit, Validators.compose([Validators.required])],
            otherCreditAmount: [this.decimalFormat(this.ocrInfos.financialSituation?.otherCreditAmount)],
            rentCredit: [this.ocrInfos.financialSituation?.rentCredit],
            rentCreditAmount: [this.decimalFormat(this.ocrInfos.financialSituation?.rentCreditAmount)],
            savingsAccount: [this.ocrInfos.financialSituation?.savingsAccount],
            savingsAccountAmount: [this.decimalFormat(this.ocrInfos.financialSituation?.savingsAccountAmount)],
            otherIncome: [this.ocrInfos.financialSituation?.otherIncome, Validators.compose([Validators.required])],
            otherCreditEndDate: [this.ocrInfos.financialSituation?.otherCreditEndDate ? this.formatDate_(this.ocrInfos.financialSituation?.otherCreditEndDate) : ''],
            bankLenderCreditOther: [this.translateLenderBank(this.ocrInfos.financialSituation?.bankLenderCreditOther)],
        });

        this.clientInfoForm.emit(this.form);

        this.filteredOptions = this.form.get('wilaya').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filter(name.toString()) : this.wilayasList.slice())
            );
        this.filteredBirthWilayas = this.form.get('birthWilaya').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.birthWilayaFilter(name.toString()) : this.birthWilayasList.slice())
            );

        this.filteredEmployerWilaya = this.form.get('employerWilaya').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filterEmplyer(name.toString()) : this.employerWilayasList.slice())
            );

        this.activityDomainOptions = this.form.get('activityDomain').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.activityDomainfilter(name.toString()) : ActivityDomainList.slice())
            );

        this.activityDomainTwoOptions = this.form.get('activityDomainTwo').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.activityDomainTwofilter(name.toString()) : ActivityDomains.slice())
            );

        this.bankLenderCreditOtherOptions = this.form.get('bankLenderCreditOther').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.bankLenderCreditOtherFilter(name.toString()) : BANK_LENDER.slice())
            );

        this.carLoanLenderBankOptions = this.form.get('carLoanLenderBank').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.bankLenderCreditOtherFilter(name.toString()) : BANK_LENDER.slice())
            );

        this.mortgageLenderBankOptions = this.form.get('mortgageLenderBank').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.bankLenderCreditOtherFilter(name.toString()) : BANK_LENDER.slice())
            );

        this.professionOptions = this.form.get('profession').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.professionfilter(name.toString()) : ProfessionList.slice())
            );

        this.form.valueChanges.subscribe(() => {

            this.socialClientCategory = this.form.get('socialClientCategory').value;
            this.professionalClientCategory = this.form.get('professionalClientCategory').value;

            if (this.form.get('savingsAccount').value === true) {

            }

            this.clientInfoForm.emit(this.form);

        });

        // Validators Manipulation Before Any Change

        if (this.form.get('savingsAccount').value === false) {
            this.form.get('savingsAccountAmount').clearValidators();
            this.form.get('savingsAccountAmount').updateValueAndValidity({onlySelf: true});
        }

        if (this.form.get('rentCredit').value === false) {
            this.form.get('rentCreditAmount').clearValidators();
            this.form.get('rentCreditAmount').updateValueAndValidity({onlySelf: true});
        }

        if (this.form.get('otherCredit').value === false) {
            this.form.get('bankLenderCreditOther').clearValidators();
            this.form.get('bankLenderCreditOther').updateValueAndValidity({onlySelf: true});

            this.form.get('otherCreditEndDate').clearValidators();
            this.form.get('otherCreditEndDate').updateValueAndValidity({onlySelf: true});

            this.form.get('otherCreditAmount').clearValidators();
            this.form.get('otherCreditAmount').updateValueAndValidity({onlySelf: true});

        }

        switch (this.form.get('professionalClientCategory').value) {
            case 'EMPLOYEE' : {
                this.form.get('dateRetraite').clearValidators();
                this.form.get('dateRetraite').updateValueAndValidity({onlySelf: true});
                this.form.get('netSalaryRENA').clearValidators();
                this.form.get('netSalaryRENA').updateValueAndValidity({onlySelf: true});
                break;
            }
            case 'RETRIED' : {
                this.form.get('montant_net').clearValidators();
                this.form.get('montant_net').updateValueAndValidity({onlySelf: true});
                break;
            }

        }

        switch (this.form.get('socialClientCategory').value) {
            case 'SINGLE' : {
                this.form.get('dependentChild').clearValidators();
                this.form.get('dependentChild').updateValueAndValidity({onlySelf: true});
                break;
            }
            case 'MARRIED' : {
                this.form.get('maidenName').clearValidators();
                this.form.get('maidenName').updateValueAndValidity({onlySelf: true});
                this.form.get('maidenNameInArabic').clearValidators();
                this.form.get('maidenNameInArabic').updateValueAndValidity({onlySelf: true});
                break;
            }
            case 'DIVORCE' : {
                this.form.get('maidenName').clearValidators();
                this.form.get('maidenName').updateValueAndValidity({onlySelf: true});
                this.form.get('maidenNameInArabic').clearValidators();
                this.form.get('maidenNameInArabic').updateValueAndValidity({onlySelf: true});
                break;
            }
            case 'WIDOWER' : {
                this.form.get('maidenName').clearValidators();
                this.form.get('maidenName').updateValueAndValidity({onlySelf: true});
                this.form.get('maidenNameInArabic').clearValidators();
                this.form.get('maidenNameInArabic').updateValueAndValidity({onlySelf: true});
                break;
            }

        }

        if (this.form.get('gender').value === 'Male') {
            this.form.get('maidenName').clearValidators();
            this.form.get('maidenName').updateValueAndValidity({onlySelf: true});
            this.form.get('maidenNameInArabic').clearValidators();
            this.form.get('maidenNameInArabic').updateValueAndValidity({onlySelf: true});
        }


        // Validators Manipulation After Changes

        this.form.get('savingsAccount').valueChanges.subscribe(change => {
            if (change === false) {
                this.form.get('savingsAccountAmount').clearValidators();
                this.form.get('savingsAccountAmount').updateValueAndValidity({onlySelf: true});
            }
        });

        this.form.get('rentCredit').valueChanges.subscribe(change => {
            if (change === false) {
                this.form.get('rentCreditAmount').clearValidators();
                this.form.get('rentCreditAmount').updateValueAndValidity({onlySelf: true});
            }
        });

        this.form.get('otherCredit').valueChanges.subscribe(change => {
            if (change === false) {
                this.form.get('bankLenderCreditOther').clearValidators();
                this.form.get('bankLenderCreditOther').updateValueAndValidity({onlySelf: true});

                this.form.get('otherCreditEndDate').clearValidators();
                this.form.get('otherCreditEndDate').updateValueAndValidity({onlySelf: true});

                this.form.get('otherCreditAmount').clearValidators();
                this.form.get('otherCreditAmount').updateValueAndValidity({onlySelf: true});
            }
        });

        this.form.get('professionalClientCategory').valueChanges.subscribe(Category => {
            if (Category === 'EMPLOYEE') {
                this.form.get('dateRetraite').clearValidators();
                this.form.get('dateRetraite').updateValueAndValidity({onlySelf: true});
                this.form.get('netSalaryRENA').clearValidators();
                this.form.get('netSalaryRENA').updateValueAndValidity({onlySelf: true});
            }

            if (Category === 'RETRIED') {
                this.form.get('montant_net').clearValidators();
                this.form.get('montant_net').updateValueAndValidity({onlySelf: true});
            }
        });


        this.form.get('socialClientCategory').valueChanges.subscribe(Category => {
            if (Category === 'SINGLE') {
                this.form.get('dependentChild').clearValidators();
                this.form.get('dependentChild').updateValueAndValidity({onlySelf: true});
            }
            if (Category !== 'MARRIED' && Category !== 'WIDOWER') {
                this.form.get('maidenName').clearValidators();
                this.form.get('maidenName').updateValueAndValidity({onlySelf: true});
                this.form.get('maidenNameInArabic').clearValidators();
                this.form.get('maidenNameInArabic').updateValueAndValidity({onlySelf: true});
            }
        });

        this.form.get('gender').valueChanges.subscribe(Category => {
            if (Category === 'Male') {
                this.form.get('maidenName').clearValidators();
                this.form.get('maidenName').updateValueAndValidity({onlySelf: true});
                this.form.get('maidenNameInArabic').clearValidators();
                this.form.get('maidenNameInArabic').updateValueAndValidity({onlySelf: true});
            }
        });

    }

    ngAfterViewInit() {
        const inputs = document.querySelectorAll('.input-arabic');
        inputs.forEach((input, index) => {
            let prevValue = input as HTMLInputElement
            this.prevValue2[index] = prevValue.value
            const keyboardClass = '.simple-keyboard' + `${this.form.get('gender').value === 'Female' && (this.socialClientCategory === 'MARRIED' || this.socialClientCategory === 'WIDOWER') ? index : index + 1}`
            const keyboard = new Keyboard(keyboardClass, {
                onChange: input => this.onChange(input, index),
                onKeyPress: button => this.onKeyPress(button, index),
                ...layout,
                rtl: true
            });
            this.keyboards.push(keyboard);
            input.addEventListener('input', (event: any) => {
                const targetInput = event.target as HTMLInputElement;
                // Update the corresponding keyboard input without replacing
                this.keyboards[index].setInput(targetInput.value);
            });
        });
    }

    onChange = (input: string, index: number) => {
        const inputs = document.querySelectorAll('.input-arabic');
        const targetInput = inputs[index] as HTMLInputElement;
        let length = this.prevValue2[index].length
        const value = targetInput.value.slice(0, length);
        targetInput.value = value + input;
    };

    onKeyPress = (button: string, index: number) => {
        const inputs = document.querySelectorAll('.input-arabic');
        const targetInput = inputs[index] as HTMLInputElement;
        if (button === "{bksp}") {
            targetInput.value = targetInput.value.slice(0, -1);
            if (targetInput.value == '') {
                this.prevValue2[index] = ''
            }
        }
        if (button === "{shift}" || button === "{lock}") {
            this.handleShift(index);
        }
    };

    handleShift = (index: number) => {
        let currentLayout = this.keyboards[index].options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboards[index].setOptions({
            layoutName: shiftToggle
        });
    };

    // check if maidenName should be required
    isMaidenNameRequired() {
        const gender = this.form.get('gender')?.value;
        // Return true if female and married, false otherwise
        return (gender === 'Female') && (gender !== null) && (this.socialClientCategory === 'MARRIED' || this.socialClientCategory === 'WIDOWER');
    }

    // check if maidenName should be required
    isDependentChildRequired() {
        // Return true if not SINGLE, false otherwise
        return this.socialClientCategory !== 'SINGLE';
    }

    // check if date of Retirement should be required
    isDateOfRetirementRequired() {
        // Return true if RETRIED or MILITARY_RETIRED , false otherwise
        return this.professionalClientCategory === 'RETRIED' || this.professionalClientCategory === 'MILITARY_RETIRED';
    }

    // check if ActivityDomain , ActivityDomainTwo, profession and recruitmentDate should be required
    isActivityDomainRequired() {
        return this.professionalClientCategory !== 'RETRIED' && this.professionalClientCategory !== 'MILITARY_RETIRED';
    }

    decimalFormat(value) {
        if (value) {
            const v_ = value.toString().replace(' ', '');
            return this.decimalFormatPipe.transform(v_);
        } else {
            return value;
        }
    }

    formatDate(input) {
        return new Date(input.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
    }


    // format date from dd-mm-yy to EEE MMM dd yyyy HH:mm:ss 'GMT'ZZZZZ (zzzz) format
    formatDate_(inputDateString) {
        if (inputDateString) {
            const [day, month, year] = inputDateString.split('-');
            return new Date(Number(year), Number(month) - 1, Number(day));
        } else {
            return null; // Return null instead of an empty string
        }
    }

    getWilayaTowns(event) {

        this.selectedWilaya = event.option.value;

        this.form.get('commune').reset();

        if (this.form.controls.wilaya.dirty) {
            this.form.controls.commune.enable();
        }

        this.getTowns(this.wilayasList.filter(value => {
            if (value.value == this.selectedWilaya) {
                return value;
            }
        })[0].id);
    }

    private townFilter(name: string): Town[] {
        const filterValue = name.toLowerCase();
        return this.towns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filter(name: string): Wilaya[] {
        const filterValue = name.toLowerCase();
        return this.wilayasList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private birthWilayaFilter(name: string): Wilaya[] {
        const filterValue = name.toLowerCase();
        return this.birthWilayasList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filterEmplyer(name: string): Wilaya[] {
        const filterValue = name.toLowerCase();
        return this.employerWilayasList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private activityDomainfilter(name: string): List[] {
        const filterValue = name.toLowerCase();
        return ActivityDomainList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private activityDomainTwofilter(name: string): List[] {
        const filterValue = name.toLowerCase();
        return ActivityDomains.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private bankLenderCreditOtherFilter(name: string): List[] {
        const filterValue = name.toLowerCase();
        return BANK_LENDER.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private professionfilter(name: string): List[] {
        const filterValue = name.toLowerCase();
        return ProfessionList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    translateLenderBank(value: string) {
        if (value === 'NONE') {
            return '';
        }
        return BANK_LENDER.find(option => option.value === value).name;
    }

    getWilayaTownsDefault(selectedWilaya) {

        if (this.selectedWilaya) {

            if (this.form.controls.wilaya.dirty) {
                this.form.controls.commune.enable();
            }

            this.getTowns(this.wilayasList.filter(value => {
                if (value.value == selectedWilaya) {
                    return value;
                }
            })[0].id);
        }
    }

    getTowns(wilayaId) {
        this.wilayaService.getTownsByWilayaId(wilayaId).subscribe(res => {
            this.towns = res;
            this.townsSubject$.next(res);
            this.townFilteredOptions = this.form.get('commune').valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value),
                    map(name => name ? this.townFilter(name.toString()) : this.towns.slice())
                );
        });
    }

    forbiddenTownsValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (this.towns.length > 0) {// below findIndex will check if control.value is equal to one of our options or not
                const index = this.towns.findIndex(town => {
                    return (new RegExp('\^' + town.name + '\$')).test(control.value);
                });
                return index < 0 ? {forbiddentowns: {value: control.value}} : null;
            }
        };
    }

    getWilayaByTownName(townName) {
        if (townName) {
            this.townService.getWilayaByTownName(townName).subscribe(res => {
                if (res) {
                    this.selectedWilaya = res;
                    this.getWilayaTownsDefault(res);
                    this.wilayaSubject$.next(res);
                    return;
                }
            });
        }
    }

    filteredbirthWilayaExecuted() {
        this.filteredBirthWilayas = this.form.get('birthWilaya').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.birthWilayaFilter(name.toString()) : this.birthWilayasList.slice())
            );
    }

    selectBirthWilaya(name: string) {
        const selectedBirthWilaya = this.birthWilayasList.find((c) => c.value === name);
        this.form
            .get('birthWilaya')
            .setValue(selectedBirthWilaya?.value);
    }
}

const ActivityDomainList = [
    {name: 'Publique', value: 'PUBLIC'},
    {name: 'Privée', value: 'PRIVATE'},
    {name: 'Non concerné', value: 'NOT_CONCERNED'}
];

const ActivityDomains = [
    {value: 'AGRICULTURE', name: 'Agriculture Chasse Services Annexes'},
    {value: 'FORESTRY', name: 'Sylviculture Exploitation Forestière Services Annexes'},
    {value: 'FISHING', name: 'Pêche Aquaculture'},
    {value: 'COAL_EXTRACTION', name: 'Extraction de Houille de Lignite et de Tourbe'},
    {value: 'HYDROCARBON_EXTRACTION', name: 'Extraction d\'Hydrocarbures Services Annexes'},
    {value: 'URANIUM_EXTRACTION', name: 'Extraction de Minerais d\'Uranium'},
    {value: 'METAL_ORE_EXTRACTION', name: 'Extraction de Minerais Métalliques'},
    {value: 'OTHER_EXTRACTION', name: 'Autres Industries Extractives'},
    {value: 'FOOD_INDUSTRIES', name: 'Industries Alimentaires'},
    {value: 'TOBACCO', name: 'Industrie du Tabac'},
    {value: 'TEXTILE', name: 'Industrie Textile'},
    {value: 'CLOTHING', name: 'Industrie de l\'Habillement et des Fourrures'},
    {value: 'LEATHER', name: 'Industrie du Cuir et de la Chaussure'},
    {value: 'WOOD', name: 'Travail du Bois et Fabrication d\'Articles en Bois'},
    {value: 'PAPER', name: 'Industrie du Papier et du Carton'},
    {value: 'PRINTING', name: 'Édition Imprimerie Reproduction'},
    {value: 'REFINING', name: 'Cokéfaction Raffinage Industries Nucléaires'},
    {value: 'CHEMICAL', name: 'Industrie Chimique'},
    {value: 'RUBBER', name: 'Industrie de Caoutchouc et des Tiques'},
    {value: 'NON_METALLIC_MINERALS', name: 'Fabrication d\'Autres Produits Minéraux Non Métalliques'},
    {value: 'METALLURGY', name: 'Métallurgie'},
    {value: 'METAL_PRODUCTS', name: 'Travail des Métaux'},
    {value: 'MACHINERY', name: 'Fabrication de Machines et Équipements'},
    {value: 'OFFICE_MACHINERY', name: 'Fabrication de Machines de Bureau et de Matériel Informatique'},
    {value: 'ELECTRICAL_MACHINERY', name: 'Fabrication de Machines et Appareils Électriques'},
    {value: 'RADIO_TV_COMMUNICATION', name: 'Fabrication d\'Équipements de Radio Télévision et Communication'},
    {value: 'MEDICAL_PRECISION', name: 'Fabrication d\'Instruments Médicaux de Précision d\'Optique et d\'Horlogerie'},
    {value: 'AUTOMOBILES', name: 'Industrie Automobile'},
    {value: 'TRANSPORT_EQUIPMENT', name: 'Fabrication d\'Autres Matériels de Transport'},
    {value: 'FURNITURE', name: 'Fabrication de Meubles Industries Diverses'},
    {value: 'RECYCLING', name: 'Récupération'},
    {value: 'ELECTRICITY_GAS', name: 'Production et Distribution d\'Électricité de Gaz et de Chaleur'},
    {value: 'WATER_SUPPLY', name: 'Captage Traitement et Distribution d\'Eau'},
    {value: 'CONSTRUCTION', name: 'Construction'},
    {value: 'AUTO_REPAIR', name: 'Commerce et Réparation Automobile'},
    {value: 'WHOLESALE', name: 'Commerce de Gros et Intermédiaires du Commerce'},
    {value: 'RETAIL', name: 'Commerce de Détail et Réparation d\'Articles Domestiques'},
    {value: 'HOTELS_RESTAURANTS', name: 'Hôtels et Restaurants'},
    {value: 'LAND_TRANSPORT', name: 'Transports Terrestres'},
    {value: 'WATER_TRANSPORT', name: 'Transports par Eau'},
    {value: 'AIR_TRANSPORT', name: 'Transports Aériens'},
    {value: 'TRANSPORT_AUXILIARY', name: 'Services Auxiliaires des Transports'},
    {value: 'POST_TELECOMMUNICATIONS', name: 'Postes et Télécommunications'},
    {value: 'FINANCIAL_INTERMEDIATION', name: 'Intermédiation Financière'},
    {value: 'INSURANCE', name: 'Assurance'},
    {value: 'FINANCIAL_AUXILIARY', name: 'Auxiliaires Financiers et d\'Assurance'},
    {value: 'REAL_ESTATE', name: 'Activités Immobilières'},
    {value: 'RENTAL', name: 'Location sans Opérateur'},
    {value: 'INFORMATION_TECH', name: 'Activités Informatiques'},
    {value: 'RESEARCH_DEVELOPMENT', name: 'Recherche et Développement'},
    {value: 'BUSINESS_SERVICES', name: 'Services Fournis Principalement aux Entreprises'},
    {value: 'PUBLIC_ADMINISTRATION', name: 'Administration Publique'},
    {value: 'EDUCATION', name: 'Éducation'},
    {value: 'HEALTH_SOCIAL', name: 'Santé et Action Sociale'},
    {value: 'WASTE_MANAGEMENT', name: 'Assainissement Voirie et Gestion des Déchets'},
    {value: 'ASSOCIATIONS', name: 'Activités Associatives'},
    {value: 'CULTURAL_SPORTS', name: 'Activités Récréatives Culturelles et Sportives'},
    {value: 'PERSONAL_SERVICES', name: 'Services Personnels'},
    {value: 'DOMESTIC_SERVICES', name: 'Services Domestiques'},
    {value: 'EXTRA_TERRITORIAL', name: 'Activités Extra Territoriales'},
    {value: 'INDIVIDUAL', name: 'Particulier'}
];

const ProfessionList = [
    {value: 'RETIREES', name: 'Retraités'},
    {value: 'UNEMPLOYED', name: 'Sans emploi'},
    {value: 'ASSOCIATES', name: 'Associés'},
    {value: 'LEGISLATIVE_MEMBERS', name: 'Membres législatifs'},
    {value: 'SENIOR_ADMINISTRATION', name: 'Administration supérieure'},
    {value: 'POLITICAL_LEADERS', name: 'Leaders politiques'},
    {value: 'DIRECTORS', name: 'Directeurs'},
    {value: 'SPECIALIZED_DIRECTORS', name: 'Directeurs spécialisés'},
    {value: 'EXECUTIVES', name: 'Cadres'},
    {value: 'PHYSICAL_SCIENCE_RESEARCHERS', name: 'Chercheurs en sciences physiques'},
    {value: 'MATHEMATICS_STATISTICS_RESEARCHERS', name: 'Chercheurs en mathématiques et statistiques'},
    {value: 'IT_SPECIALISTS', name: 'Spécialistes en informatique'},
    {value: 'OTHER_ENGINEERS', name: 'Autres ingénieurs'},
    {value: 'LIFE_SCIENCE_SPECIALISTS', name: 'Spécialistes en sciences de la vie'},
    {value: 'MEDICAL_PROFESSIONALS', name: 'Professionnels de la santé'},
    {value: 'UNIVERSITY_PROFESSORS', name: 'Professeurs d\'université'},
    {value: 'SECONDARY_EDUCATION_PROFESSORS', name: 'Professeurs de l\'enseignement secondaire'},
    {value: 'OTHER_EDUCATION_SPECIALISTS', name: 'Autres spécialistes de l\'éducation'},
    {value: 'ADMINISTRATIVE_COMMERCIAL_SPECIALISTS', name: 'Spécialistes administratifs et commerciaux'},
    {value: 'LEGAL_PROFESSIONALS', name: 'Professionnels du droit'},
    {value: 'ARCHIVISTS_LIBRARIANS', name: 'Archivistes et bibliothécaires'},
    {value: 'SOCIAL_HUMAN_SCIENCES_SPECIALISTS', name: 'Spécialistes des sciences sociales et humaines'},
    {value: 'WRITERS_ARTISTS', name: 'Écrivains et artistes'},
    {value: 'CLERGY_MEMBERS', name: 'Membres du clergé'},
    {value: 'PHYSICAL_TECHNICAL_SCIENCES_TECHNICIANS', name: 'Techniciens en sciences physiques et techniques'},
    {value: 'OTHER_IT_OPERATORS', name: 'Autres opérateurs en technologies de l\'information'},
    {value: 'OPTICAL_ELECTRONIC_EQUIPMENT_TECHNICIANS', name: 'Techniciens en équipement optique et électronique'},
    {value: 'AIR_TRANSPORT_TECHNICIANS', name: 'Techniciens en transport aérien'},
    {value: 'SAFETY_HEALTH_QUALITY_INSPECTORS', name: 'Inspecteurs en sécurité, santé et qualité'},
    {value: 'TECHNICAL_NAVIGATING_PERSONNEL', name: 'Personnel technique de navigation'},
    {value: 'LIFE_SCIENCE_HEALTH_WORKERS', name: 'Travailleurs en sciences de la vie et de la santé'},
    {value: 'MODERN_MEDICINE_INTERMEDIATES', name: 'Intermédiaires en médecine moderne'},
    {value: 'NURSES', name: 'Infirmières'},
    {value: 'HEALTH_SUPERVISORS_MIDWIVES', name: 'Superviseurs de santé et sages-femmes'},
    {value: 'TRADITIONAL_MEDICINE_PRACTITIONERS', name: 'Praticiens de la médecine traditionnelle'},
    {value: 'PRIMARY_EDUCATION_INTERMEDIATES', name: 'Intermédiaires en éducation primaire'},
    {value: 'PRESCHOOL_EDUCATION_INTERMEDIATES', name: 'Intermédiaires en éducation préscolaire'},
    {value: 'SPECIAL_EDUCATION_INTERMEDIATES', name: 'Intermédiaires en éducation spécialisée'},
    {value: 'OTHER_EDUCATION_INTERMEDIATES', name: 'Autres intermédiaires en éducation'},
    {value: 'FINANCE_SALES_INTERMEDIATES', name: 'Intermédiaires en finance et ventes'},
    {value: 'COMMERCIAL_AGENTS_BROKERS', name: 'Agents commerciaux et courtiers'},
    {value: 'ADMINISTRATIVE_MANAGEMENT_INTERMEDIATES', name: 'Intermédiaires en gestion administrative'},
    {value: 'PUBLIC_ADMINISTRATION_INSPECTORS', name: 'Inspecteurs de l\'administration publique'},
    {value: 'CRIMINAL_POLICE_INSPECTORS_DETECTIVES', name: 'Inspecteurs de police criminelle et détectives'},
    {value: 'SOCIAL_WORK_INTERMEDIATES', name: 'Intermédiaires en travail social'},
    {value: 'CREATIVE_PERFORMING_ARTS_INTERMEDIATES', name: 'Intermédiaires en arts créatifs et du spectacle'},
    {value: 'LAY_CLERGY', name: 'Clergé laïc'},
    {value: 'RADIO_TV_PRESENTATORS', name: 'Présentateurs radio et télévision'},
    {value: 'STENOGRAPHIC_SECRETARIES', name: 'Secrétaires sténographes'},
    {value: 'ACCOUNTING_FINANCE_SERVICES_EMPLOYEES', name: 'Employés des services comptables et financiers'},
    {
        value: 'STOCK_MANAGEMENT_ORDONNANCE_AGENTS_TRANSPORT_ADMINISTRATIVE_EMPLOYEES',
        name: 'Agents de gestion des stocks, agents d\'ordonnancement et employés administratifs du transport'
    },
    {value: 'DOCUMENTATION_MAIL_EMPLOYEES', name: 'Employés de la documentation et du courrier'},
    {value: 'OTHER_OFFICE_EMPLOYEES', name: 'Autres employés de bureau'},
    {value: 'CASHIERS', name: 'Caissiers'},
    {value: 'CUSTOMER_SERVICE_INFORMATION_PERSONNEL', name: 'Personnel du service client et de l\'information'},
    {value: 'TOURISM_GUIDES', name: 'Guides touristiques'},
    {value: 'RESTAURANT_SERVICE_EMPLOYEES', name: 'Employés de service en restaurant'},
    {value: 'CHILD_CARE_AIDES_HEALTH_ASSISTANTS', name: 'Aides à la garde d\'enfants et assistants de santé'},
    {value: 'OTHER_DIRECT_SERVICES_TO_INDIVIDUALS', name: 'Autres services directs aux particuliers'},
    {value: 'ASTROLOGERS_FORTUNE_TELLERS', name: 'Astrologues et diseurs de bonne aventure'},
    {value: 'PROTECTION_SECURITY_PERSONNEL', name: 'Personnel de protection et de sécurité'},
    {value: 'COMMERCIAL_NAVIGATING_PERSONNEL', name: 'Personnel navigant commercial'},
    {value: 'MODELS', name: 'Mannequins'},
    {value: 'STORE_SALES_DEMONSTRATORS', name: 'Démonstrateurs de vente en magasin'},
    {value: 'RETAIL_STALL_MARKET_SALES', name: 'Ventes au détail sur étal de marché'},
    {value: 'AGRICULTURE_MARKET_CROPS_WORKERS', name: 'Travailleurs du marché de l\'agriculture et des cultures'},
    {value: 'LIVESTOCK_MARKET_WORKERS', name: 'Travailleurs du marché du bétail'},
    {value: 'POLYCULTURE_LIVESTOCK_MARKET_WORKERS', name: 'Travailleurs du marché de la polyculture et de l\'élevage'},
    {value: 'FORESTRY_PROFESSIONALS', name: 'Professionnels de la foresterie'},
    {value: 'FISHERS_HUNTERS_TRAPPERS', name: 'Pêcheurs, chasseurs et trappeurs'},
    {value: 'SUBSISTENCE_AGRICULTURE_FISHERY_WORKERS', name: 'Travailleurs de l\'agriculture vivrière et de la pêche'},
    {value: 'MINERS_QUARRIERS_MINING_WORKERS', name: 'Mineurs, carriers et travailleurs de l\'extraction minière'},
    {value: 'BUILDING_CONSTRUCTION_WORKERS', name: 'Travailleurs de la construction de bâtiments'},
    {value: 'BUILDING_FINISHING_WORKERS', name: 'Travailleurs de la finition des bâtiments'},
    {value: 'PAINTERS_BUILDING_RENOVATORS', name: 'Peintres et rénovateurs de bâtiments'},
    {value: 'FOUNDRY_MOULDERS_WELDERS', name: 'Fondeurs, mouleurs et soudeurs'},
    {value: 'FORGERS_TOOLMAKERS', name: 'Forgerons et outilleurs'},
    {value: 'MECHANICS_WORKERS', name: 'Mécaniciens'},
    {value: 'ELECTRICAL_ELECTRONIC_APPLIANCE_MECHANICS', name: 'Mécaniciens d\'appareils électriques et électroniques'},
    {value: 'METAL_PRECISION_WORKERS', name: 'Travailleurs de la métallurgie de précision'},
    {value: 'POTTERS_GLASS_BLOWERS_SIMILAR_WORKERS', name: 'Potiers et souffleurs de verre et travailleurs similaires'},
    {
        value: 'MANUAL_WORKERS_WOOD_TEXTILE_LEATHER_MATERIALS',
        name: 'Travailleurs manuels du bois, des textiles et du cuir'
    },
    {
        value: 'PRINTING_INDUSTRY_WORKERS_SIMILAR',
        name: 'Travailleurs de l\'industrie de l\'impression et travailleurs similaires'
    },
    {value: 'FOOD_INDUSTRY_WORKERS', name: 'Travailleurs de l\'industrie alimentaire'},
    {
        value: 'CABINETMAKERS_JOINERS_WOOD_TREATMENT_WORKERS',
        name: 'Ébénistes, menuisiers et travailleurs du traitement du bois'
    },
    {value: 'TEXTILE_CLOTHING_WORKERS', name: 'Travailleurs du textile et de l\'habillement'},
    {value: 'LEATHER_SKIN_SHOE_WORKERS', name: 'Travailleurs du cuir, de la peau et de la chaussure'},
    {value: 'MINERAL_EXTRACTION_TREATMENT_OPERATORS', name: 'Opérateurs de traitement de l\'extraction de minéraux'},
    {value: 'METAL_TRANSFORMATION_OPERATORS', name: 'Opérateurs de transformation des métaux'},
    {value: 'GLASS_CERAMIC_MATERIALS_OPERATORS', name: 'Opérateurs de matériaux en verre et céramique'},
    {value: 'WOOD_PAPER_MANUFACTURING_OPERATORS', name: 'Opérateurs de fabrication du bois et du papier'},
    {value: 'CHEMICAL_PLANT_OPERATORS', name: 'Opérateurs d\'installations chimiques'},
    {
        value: 'ENERGY_PRODUCTION_OPERATORS_SIMILAR',
        name: 'Opérateurs de production d\'énergie et travailleurs similaires'
    },
    {value: 'AUTOMATED_ASSEMBLY_ROBOT_OPERATORS', name: 'Opérateurs d\'assemblage automatisé et de robots'},
    {
        value: 'CONCRETE_MACHINERY_TOOL_OPERATORS_SIMILAR',
        name: 'Opérateurs de machines et d\'outils en béton et travailleurs similaires'
    },
    {
        value: 'CHEMICAL_PRODUCT_MANUFACTURING_MACHINE_OPERATORS',
        name: 'Opérateurs de machines de fabrication de produits chimiques'
    },
    {
        value: 'RUBBER_PLASTIC_PRODUCT_MANUFACTURING_MACHINE_OPERATORS',
        name: 'Opérateurs de machines de fabrication de produits en caoutchouc et en plastique'
    },
    {
        value: 'WOOD_PRODUCT_MANUFACTURING_MACHINE_OPERATORS',
        name: 'Opérateurs de machines de fabrication de produits en bois'
    },
    {
        value: 'PRINTING_PAPER_CARD_BOARD_MACHINE_OPERATORS',
        name: 'Opérateurs de machines d\'impression, de papier et de carton'
    },
    {value: 'TEXTILE_MACHINE_OPERATORS', name: 'Opérateurs de machines textiles'},
    {value: 'FOOD_INDUSTRY_MACHINE_OPERATORS', name: 'Opérateurs de machines de l\'industrie alimentaire'},
    {value: 'ASSEMBLY_WORKERS', name: 'Ouvriers d\'assemblage'},
    {
        value: 'OTHER_FIXED_MACHINE_OPERATORS_ASSEMBLY_WORKERS',
        name: 'Autres opérateurs de machines fixes et ouvriers d\'assemblage'
    },
    {
        value: 'LOCOMOTIVE_OPERATORS_RAIL_VEHICLE_MANEUVERING_WORKERS',
        name: 'Conducteurs de locomotives et travailleurs en manœuvres de véhicules sur rail'
    },
    {value: 'MOTOR_VEHICLE_OPERATORS', name: 'Conducteurs de véhicules à moteur'},
    {
        value: 'EARTH_MOVING_AGRICULTURAL_MOBILE_EQUIPMENT_OPERATORS',
        name: 'Opérateurs d\'équipements mobiles de terrassement et agricoles'
    },
    {value: 'DECK_CREW_SIMILAR_WORKERS', name: 'Équipage de pont et travailleurs similaires'},
    {value: 'STREET_VENDORS_SIMILAR', name: 'Vendeurs de rue et travailleurs similaires'},
    {
        value: 'SHOESHINERS_STREET_PERFORMERS_SIMILAR',
        name: 'Cireurs de chaussures, artistes de rue et travailleurs similaires'
    },
    {value: 'HOUSEHOLD_CLEANING_PERSONNEL_SIMILAR', name: 'Personnel de nettoyage ménager et travailleurs similaires'},
    {
        value: 'BUILDING_MAINTENANCE_WINDOW_CLEANERS_SIMILAR',
        name: 'Personnel d\'entretien des bâtiments et laveurs de vitres et travailleurs similaires'
    },
    {value: 'COURIERS_PORTERS_GUARDS_SIMILAR', name: 'Courriers, portiers et gardes et travailleurs similaires'},
    {value: 'GARBAGE_COLLECTORS_SWEEPERS_SIMILAR', name: 'Éboueurs, balayeurs et travailleurs similaires'},
    {
        value: 'UNSKILLED_AGRICULTURE_FISHERY_FORESTRY_MANEUVERING_SIMILAR',
        name: 'Travailleurs non qualifiés de l\'agriculture, de la pêche et de la foresterie et travailleurs similaires'
    },
    {
        value: 'MINING_CONSTRUCTION_MANEUVERING_SIMILAR',
        name: 'Manœuvres de l\'extraction minière, de la construction et travailleurs similaires'
    },
    {
        value: 'MANUFACTURING_INDUSTRY_MANEUVERING_SIMILAR',
        name: 'Manœuvres de l\'industrie manufacturière et travailleurs similaires'
    },
    {value: 'TRANSPORT_MANEUVERING_SIMILAR', name: 'Manœuvres du transport et travailleurs similaires'}
];
