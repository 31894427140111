import {AbstractService} from "../../services/abstract-service.service";
import {CheckListModel} from "../ek-models/checkList.model";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {GenericObject} from "../ek-models/generic-object.model";

@Injectable()
export class ChecklistService extends AbstractService<CheckListModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}checkList`;
    }

    public getCheckListByFolderId(idFolder: number, documentGender: string, documentTitle: string): Observable<CheckListModel> {
        let section;

        if (documentGender) {
            switch (documentGender) {
                case 'BIRTH_ACT':
                    section = 'BirthCertificate';
                    break;
                case 'FRONT_IDENTITY_CARD':
                    section = 'BiometricNationalCard';
                    break;
                case 'BACK_IDENTITY_CARD':
                    section = 'BiometricNationalCard';
                    break;
                case 'REQUEST_FOR_FINANCING':
                    section = 'FundingRequest';
                    break;
                case 'AUTHORIZATION_TO_CONSULT_THE_CREDIT_BUREAU':
                    section = 'ConsultationAuthorizationFromCremBA';
                    break;
                case 'RESIDENCE':
                    section = 'ResidenceCard';
                    break;
                case 'PAY_SLIP':
                    section = 'PaySlipsOne';
                    break;
                case 'PAYSLIP_TWO':
                    section = 'PaySlipsTwo';
                    break;
                case 'PAYSLIP_THREE':
                    section = 'PaySlipsThree';
                    break;
                case 'SIGNATURE_SHEET':
                    section = 'SignatureSheet';
                    break;
                case 'FAMILY_SHEET':
                    section = 'FamilySheet';
                    break;
                case 'CROSSED_CHECK':
                    section = 'Check';
                    break;
                case 'CARD_CHIFA':
                    section = 'ChifaCard';
                    break;
                case 'TERMINATION_CERTIFICATE':
                    section = 'Radiation';
                    break;
                case 'CORP_PRESENCE':
                    section = 'CorpPresence';
                    break;
                case 'RETREAT':
                    section = 'Retirement';
                    break;
                case 'INCOME_CERTIFICATE':
                    section = 'Retirement';
                    break;
                case 'RENA':
                    section = 'RENA';
                    break;
                case 'BANK_STATEMENT':
                    section = 'BankStatement';
                    break;
                case 'WORK_CERTIFICATE':
                    section = 'WorkCertificate';
                    break;
                case 'INCOME_CERTIFICATE_MILITARY':
                    section = 'MilitaryIncomeCertificate';
                    break;
                case 'DRIVING_LICENSE':
                    section = 'DrivingLicence';
                    break;
            }
        } else {
            switch (documentTitle) {
                case 'DIRECT_DEBIT_AUTHORIZATION':
                    section = 'CCPDebitAuthorization';
                    break;
                case 'FACTA':
                    section = 'Facta';
                    break;
                case 'ACCOUNT_OPENING_REQUEST':
                    section = 'RequestOpeningCheckingAccount';
                    break;
                case 'COMMITMENT_TO_MAINTAIN_SUFFICIENT_BALANCE_UNDER_CCP_ACCOUNT':
                    section = 'CommitementProvisionCCPAccount';
                    break;

            }
        }

        const url = `${this.url}/${idFolder}/${section}`;
        return this.http.get<Response<CheckListModel>>(url).pipe(map(({body}) => body));

    }

    public updateChecklist(idFolder: number, documentGender: string, documentTitle: string, finalCheck: GenericObject<any>) {
        let section;
        if (documentGender) {
            switch (documentGender) {
                case 'BIRTH_ACT':
                    section = 'BirthCertificate';
                    break;
                case 'FRONT_IDENTITY_CARD':
                    section = 'BiometricNationalCard';
                    break;
                case 'BACK_IDENTITY_CARD':
                    section = 'BiometricNationalCard';
                    break;
                case 'REQUEST_FOR_FINANCING':
                    section = 'FundingRequest';
                    break;
                case 'AUTHORIZATION_TO_CONSULT_THE_CREDIT_BUREAU':
                    section = 'ConsultationAuthorizationFromCremBA';
                    break;
                case 'RESIDENCE':
                    section = 'ResidenceCard';
                    break;
                case 'PAY_SLIP':
                    section = 'PaySlipsOne';
                    break;
                case 'PAYSLIP_TWO':
                    section = 'PaySlipsTwo';
                    break;
                case 'PAYSLIP_THREE':
                    section = 'PaySlipsThree';
                    break;
                case 'SIGNATURE_SHEET':
                    section = 'SignatureSheet';
                    break;
                case 'FAMILY_SHEET':
                    section = 'FamilySheet';
                    break;
                case 'CROSSED_CHECK':
                    section = 'Check';
                    break;
                case 'CARD_CHIFA':
                    section = 'ChifaCard';
                    break;
                case 'TERMINATION_CERTIFICATE':
                    section = 'Radiation';
                    break;
                case 'CORP_PRESENCE':
                    section = 'CorpPresence';
                    break;
                case 'RETREAT':
                    section = 'Retirement';
                    break;
                case 'INCOME_CERTIFICATE':
                    section = 'Retirement';
                    break;
                case 'RENA':
                    section = 'RENA';
                    break;
                case 'BANK_STATEMENT':
                    section = 'BankStatement';
                    break;
                case 'WORK_CERTIFICATE':
                    section = 'WorkCertificate';
                    break;
                case 'INCOME_CERTIFICATE_MILITARY':
                    section = 'MilitaryIncomeCertificate';
                    break;
                case 'DRIVING_LICENSE':
                    section = 'DrivingLicence';
                    break;
            }
        } else if (documentTitle) {
            switch (documentTitle) {
                case 'DIRECT_DEBIT_AUTHORIZATION':
                    section = 'CCPDebitAuthorization';
                    break;
                case 'FACTA':
                    section = 'Facta';
                    break;
                case 'ACCOUNT_OPENING_REQUEST':
                    section = 'RequestOpeningCheckingAccount';
                    break;
                case 'COMMITMENT_TO_MAINTAIN_SUFFICIENT_BALANCE_UNDER_CCP_ACCOUNT':
                    section = 'CommitementProvisionCCPAccount';
                    break;
            }
        }
        const url = `${this.url}/changestate/${idFolder}/${section}`;
        return this.http.put<Response<any>>(url, finalCheck);
    }


    public convertDocStateToEvent(checkListState): string {

        switch (checkListState) {
            case 'OCR_VALIDATED':
                return 'OCR_V_TO_MANUAL_NV';
                break;
            case 'OCR_NOT_VALIDATED':
                return 'OCR_NV_TO_MANUAL_NV';
                break;
            case 'NOT_AFFECTED_BY_OCR':
                return 'NOT_OCR_TO_MANUAL_NV';
                break;
            case 'OCR_NOT_SUPPORTED':
                return 'OCR_NS_TO_MANUAL_NV';
                break;
            case 'OCR_VALIDATED_MANUAL_VALIDATED':
                return 'OCR_V_TO_MANUAL_V';
                break;
            case 'OCR_VALIDATED_MANUAL_NOT_VALIDATED':
                return 'OCR_V_TO_MANUAL_NV';
                break;
            case 'OCR_NOT_VALIDATED_MANUAL_NOT_VALIDATED':
                return 'OCR_NV_TO_MANUAL_NV';
                break;
            case 'OCR_NOT_VALIDATED_MANUAL_VALIDATED':
                return 'OCR_NV_TO_MANUAL_V';
                break;
            case 'OCR_NOT_SUPPORTED_MANUAL_NOT_VALIDATED':
                return 'OCR_NS_TO_MANUAL_NV';
                break;
            case 'OCR_NOT_SUPPORTED_MANUAL_VALIDATED':
                return 'OCR_NS_TO_MANUAL_V';
                break;
            case 'NOT_AFFECTED_BY_OCR_MANUAL_VALIDATED':
                return 'NOT_OCR_TO_MANUAL_V';
                break;
            case 'NOT_AFFECTED_BY_OCR_MANUAL_NOT_VALIDATED':
                return 'NOT_OCR_TO_MANUAL_NV';
                break;
            default:
                return checkListState;
        }
    }

}
