import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[ktNumberInput]'
})
export class NumberInputDirective {

    constructor(private el: ElementRef) {
    }

    @HostListener('input', ['$event'])

    onInputChange(event: Event): void {

        const input = event.target as HTMLInputElement;
        let value = input.value;

        // Remove any non-numeric characters
        value = value.replace(/[^0-9]/g, '');

        if (value !== input.value) {
            input.value = '';
            return;
        }
        // Convert value to a number
        let numericValue = parseInt(value, 12);

        // Restrict value between 0 and 10
        if (numericValue < 5) {
            numericValue = 5;
        } else if (numericValue > 12) {
            numericValue = 12;
        }

        // Update the input value
        input.value = numericValue.toString();
    }

}
